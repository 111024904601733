import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const [areaData, setAreaData] = useState({
		chartheight: 400,
		series: [{
			name: "STOCK ABC",
			data: []
		}],
		options: {
			chart: {
				type: 'area',
				height: 350,
				zoom: {
					enabled: false
				}
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},

			title: {
				text: 'Fundamental Analysis of Stocks',
				align: 'left'
			},
			subtitle: {
				text: 'Price Movements',
				align: 'left'
			},
			labels: [],
			xaxis: {
				type: 'datetime',
			},
			yaxis: {
				opposite: true
			},
			legend: {
				horizontalAlign: 'left'
			}
		},
	})
	const [pieChartData, setPieChartData] = useState({
		// ...defaultChartData,
		charttype: 'donut',
		series: [],
		chartheight: 300,
		chartwidth: 500,
		options: {
			// colors: ['#F44336', '#E91E63', '#9C27B0'],
			chart: {
				type: 'pie',
			},
			labels: [
				t('UNKNOWN_CLIENTS'),
				'Клиенты от 10 до 20 лет',
				'Клиенты от 21 до 30 лет',
				'Клиенты от 31 до 40 лет',
				'Клиенты от 41 до 50 лет',
				'Клиенты от 50+',
			],
			dataLabels: {
				enabled: true
			},
			fill: {
				type: 'gradient',
			},
			legend: {
				show: false,
				position: 'bottom',
				formatter: function (val, opts) {
					return val + " - " + Math.round(opts.w.globals.series[opts.seriesIndex]) + ' %'
				},
				showForSingleSeries: false,
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: "left",
						labels: {
							colors: "#FFFFFF",
							useSeriesColors: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
				}
			}]
		},
	})

	const [show] = useState(!false)

	const [data, setData] = useState({})
	const [income, setIncome] = useState({})
	const [clientsFlow, setClientsFlow] = useState({})

	async function getClientInfo() {
		const response = await GET('/services/web/api/dashboard-info-client')
		setData(response)
	}

	async function getIncome() {
		const response = await GET('/services/web/api/analytic/tariff/income')
		if (response) {
			setIncome(response)
			var series = {
				name: "STOCK ABC",
				data: []
			}
			var options = { ...areaData.options }
			for (let i = 0; i < response.length; i++) {
				console.log(response);
				
				series.data.push(response[i]['x'])
				options.labels.push(response[i]['y'])
			}
			console.log(series);
			console.log(options);

			setAreaData({
				...areaData,
				series: series,
				options: options,
			})
		}
	}

	async function getAvgSubscriptionPeriod() {
		const response = await GET('/services/web/api/analytic/tariff/avg-subscription-period?tariff=4')
		setPieChartData({
			...pieChartData, series: [
				response.percentAgeUnknown,
				response.age10To20Percentage,
				response.age21To30Percentage,
				response.age31To40Percentage,
				response.age41To50Percentage,
				response.age50plusPercentage,
			]
		})
	}

	async function getClientsFlow() {
		const response = await GET('/services/web/api/analytic/tariff/clients-flow')
		if (response) {
			setClientsFlow(response)
		}
	}

	async function getData() {
		getClientInfo()
		getIncome()
		getAvgSubscriptionPeriod()
		getClientsFlow()
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('STATISTICS')}
				</h4>
			</div>

			{show &&
				<>
					<>
						<div className="row mb-3">
							<div className="col-md-6">
								<div className="card h-100">
									<div className="card-body">
										<div className="d-flex gap-4">
											<div className="d-flex justify-content-between align-items-center w-100">
												<div className="dashboard-title">
													{t('GENERAL_CLIENTS')}
													<span>{formatMoney(data.totalCount, 0)}</span>
												</div>
												<div className="py-2">
													{/* <ReactApexChart
														options={pieChartData.options}
														series={pieChartData.series}
														type="pie"
														height={pieChartData.chartheight}
														width={pieChartData.chartwidth}
													/> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card h-100">
									<div className="card-body">
										<div className="d-flex align-items-center">
											<div className="w-100">
												<div className="mb-3">
													<div className="progress-bar">
														<div className="progress-bar-indicator" style={{ width: `${data.age10To20Percentage}%` }} />
													</div>
													<div className="d-flex justify-content-between progress-bar-title">
														<div>
															{t('Клиенты от 10 до 20 лет')}
														</div>
														<div>
															{Math.round(data.age10To20Percentage)} %
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="progress-bar">
														<div className="progress-bar-indicator" style={{ width: `${data.age21To30Percentage}%` }} />
													</div>
													<div className="d-flex justify-content-between progress-bar-title">
														<div>
															{t('Клиенты от 21 до 30 лет')}
														</div>
														<div>
															{Math.round(data.age21To30Percentage)} %
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="progress-bar">
														<div className="progress-bar-indicator" style={{ width: `${data.age31To40Percentage}%` }} />
													</div>
													<div className="d-flex justify-content-between progress-bar-title">
														<div>
															{t('Клиенты от 31 до 40 лет')}
														</div>
														<div>
															{Math.round(data.age31To40Percentage)} %
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="progress-bar">
														<div className="progress-bar-indicator" style={{ width: `${data.age41To50Percentage}%` }} />
													</div>
													<div className="d-flex justify-content-between progress-bar-title">
														<div>
															{t('Клиенты от 41 до 50 лет')}
														</div>
														<div>
															{Math.round(data.age41To50Percentage)} %
														</div>
													</div>
												</div>
												<div className="mb-3">
													<div className="progress-bar">
														<div className="progress-bar-indicator" style={{ width: `${data.age50plusPercentage}%` }} />
													</div>
													<div className="d-flex justify-content-between progress-bar-title">
														<div>
															{t('Клиенты от 50+')}
														</div>
														<div>
															{Math.round(data.age50plusPercentage)} %
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card mt-2">
								<ReactApexChart
									options={areaData.options}
									series={areaData.series}
									type="area"
									height={areaData.chartheight}
								/>
							</div>
						</div>
					</>
					{false &&
						<>
							<div className="row mb-3">
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
								<div className="col-md-3">
									<Skeleton height={90.2} />
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<Skeleton height={300} />
								</div>
							</div>
						</>
					}
				</>
			}
		</>
	)
}

export default Index
