import React, { useEffect, useState } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

import { GET, POST, PUT, FILE } from 'helpers/adminApi'

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate()

	const [regions, setRegions] = useState([])
	const [cities, setCities] = useState([])
	const [data, setData] = useState({
		brandImageUrl: '',
	})

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/admin/api/upload/image', formData);
		setData({ ...data, 'brandImageUrl': response.url })
	}

	async function createUpdate(e) {
		e.preventDefault()
		var dataCopy = { ...data }

		var response;
		if (dataCopy.id) {
			response = await PUT('/services/admin/api/company', dataCopy, { loader: true })
			if (response) {
				navigate('/admin/company')
			}
		} else {
			response = await POST('/services/admin/api/company', dataCopy, { loader: true })
			if (response) {
				navigate('/admin/company')
			}
		}
	}

	async function selectRegion(option) {
		var dataCopy = { ...data }
		dataCopy.regionId = option.id
		dataCopy.cityId = null
		setData(dataCopy)

		const response = await GET('/services/admin/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await GET(`/services/admin/api/company/${id}`)

		setData(response)
	}

	async function getRegions() {
		const response = await GET('/services/admin/api/region-helper')
		if (response.length > 0) {
			setRegions(response)
		}

		getCities(10)
	}

	async function getCities(id) {
		const response = await GET(`/services/admin/api/city-helper/${id}`)
		if (response.length > 0) {
			setCities(response)
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		}
		getRegions()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('COMPANY')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('EDIT')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.brandImageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file"
													onClick={() => setData({ ...data, 'brandImageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://cabinet.kettma.uz' + data.brandImageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!data.brandImageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
								<div className="form-group">
									<label>{t('LOGIN')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="login"
										value={data.login}
										onChange={(e) => setData({ ...data, 'login': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="password"
										value={data.password}
										onChange={(e) => setData({ ...data, 'password': e.target.value })} />
								</div>

							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('SALE_POINT_NAME')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name"
										value={data.name}
										onChange={(e) => setData({ ...data, 'name': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PHONE')}<span className="required-mark">*</span></label>
									<input type="number" className="form-control" name="phone1"
										value={data.phone1}
										onChange={(e) => setData({ ...data, 'phone1': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PHONE')} 2</label>
									<input type="number" className="form-control" name="phone2"
										value={data.phone2}
										onChange={(e) => setData({ ...data, 'phone2': e.target.value })} />
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('INN')}</label>
									<input type="text" className="form-control" name="tin"
										value={data.tin}
										onChange={(e) => setData({ ...data, 'tin': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('CHECKING_ACCOUNT')}</label>
									<input type="text" className="form-control" name="rs"
										value={data.rs}
										onChange={(e) => setData({ ...data, 'rs': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('MFO')}</label>
									<input type="text" className="form-control" name="mfo"
										value={data.mfo}
										onChange={(e) => setData({ ...data, 'mfo': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('OKED')}</label>
									<input type="text" className="form-control" name="oked"
										value={data.oked}
										onChange={(e) => setData({ ...data, 'oked': e.target.value })} />
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<label>{t('ADDRESS')}</label>
									<input type="text" className="form-control" name="address"
										value={data.address}
										onChange={(e) => setData({ ...data, 'address': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('REGION')}<span className="required-mark">*</span></label>
									<Select
										options={regions}
										value={regions.find(option => option.id === data.regionId) || ''}
										onChange={(option => selectRegion(option))}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
								<div className="form-group">
									<label>{t('CITY')}<span className="required-mark">*</span></label>
									<Select
										options={cities}
										value={cities.find(option => option.id === data.cityId) || ''}
										onChange={(option) => setData({ ...data, 'cityId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/admin/company">{t('CANCEL')}</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

