import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import DatePicker from "react-datepicker"
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'
import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { GET } from 'helpers/api'
import { formatMoney, regexPhoneNumber, formatDate, formatDateBackend } from 'helpers/helpers'

function Preview() {
	const { id } = useParams()
	const URL = '/services/web/api/users-transactions-pageList/'
	const { t } = useTranslation()

	const date = new Date()

	const settings = useSelector(state => state.settings)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	// const [filterDataExist, setFilterDataExist] = useState(false)

	const [pageCount, setPageCount] = useState(0)

	const [data, setData] = useState([])
	const [tariffs, setTariffs] = useState([])

	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		search: '',
		startAge: '',
		endAge: '',
	})


	async function searchWithFilter() {
		const response = await GET(URL, filterData, { loader: true })
		if (response) {
			setData(response)
			setDropdownIsOpen(!dropdownIsOpen)
			// setFilterDataExist(true)
		}
	}

	async function getData() {
		const response = await GET(URL + id, {}, { loader: true })
		if (response) {
			setData(response)
		}
	}

	async function getTariffs() {
		const response = await GET(`/services/web/api/tariff-helper-company`, {})
		if (response) {
			setTariffs(response)
		}
	}

	async function paginate(data) {
		const response = await GET(URL + id, { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
		getTariffs()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('CLIENTS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('TRANSACTIONS')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL + id} search={search} getData={getData}></Search>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('TARIFF')}</label>
											<Select
												options={tariffs}
												value={tariffs.find(option => option.id === filterData.tariffId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'tariffId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME')}</th>
									<th className="text-center">{t('LAST_NAME')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('TELEGRAM')}</th>
									<th className="text-center">{t('TARIFF')}</th>
									<th className="text-center">{t('TARIFF_AMOUNT')}</th>
									<th className="text-center">{t('INCOME')}</th>
									<th className="text-center">{t('CARD_NUMBER')}</th>
									<th className="text-center">{t('SUCCESS')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('RETURN')}</th>
									<th className="text-end">{t('DATE_OF_RETURN')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.userName}</td>
										<td className="text-center">{item.userLastName}</td>
										<td className="text-center">{regexPhoneNumber(item.userLogin)}</td>
										<td className="text-center">{regexPhoneNumber(item.telegramPhone)}</td>
										<td className="text-center">{item.tariffName}</td>
										<td className="text-center">{formatMoney(item.tariffAmount)}</td>
										<td className="text-center">{formatMoney(item.amount)}</td>
										<td className="text-center">{item.cardNumber}</td>
										<td className={"text-center " + (item.success ? 'text-success' : 'text-danger')}>
											{item.success ? t('YES') : t('NO')}
										</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="text-center" >{item.reverse}</td>
										<td className="text-end">{formatDate(item.reverseDate)}</td>
										{/* <td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-primary-button"
													to={`/user/tariff/${item.orderId}`}>
													<i className="uil-eye"></i>
												</Link>
											</div>
										</td> */}
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Preview