import React, { useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { GET, PUT, FILE } from 'helpers/adminApi'
import { findFromArrayById } from "helpers/helpers"

function Index() {
	const { t } = useTranslation();
	const navigate = useNavigate()

	const [regions, setRegions] = useState([])
	const [cities, setCities] = useState([])
	const [data, setData] = useState({
		imageUrl: '',
	})

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/admin/api/upload/image', formData);
		setData({ ...data, 'imageUrl': response.url })
	}

	async function createUpdate(e) {
		e.preventDefault()
		var dataCopy = { ...data }

		var response;
		response = await PUT('/services/web/api/company', dataCopy, { loader: true })
		if (response) {
			navigate('/company')
		}
	}

	async function getData() {
		const response = await GET(`/services/web/api/company`)
		setData(response)
	}

	async function getRegions() {
		const response = await GET('/services/admin/api/region-helper')
		if (response.length > 0) {
			setRegions(response)
		}

		getCities(10)
	}

	async function getCities(id) {
		const response = await GET(`/services/admin/api/city-helper/${id}`)
		if (response.length > 0) {
			setCities(response)
		}
	}

	useEffect(() => {
		getData()
		getRegions()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('COMPANY')}
					</h4>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-3">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file"
													onClick={() => setData({ ...data, 'imageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://cabinet.kettma.uz' + data.imageUrl}
													style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
											</div>
										}
										{!data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => fileUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="company-field">
									<label>{t('SALE_POINT_NAME')}</label>
									<div className="company-value">
										{data.name}
									</div>
								</div>
								<div className="company-field">
									<label>{t('PHONE')}</label>
									<div className="company-value">
										{data.phone1}
									</div>
								</div>
								<div className="company-field">
									<label>{t('PHONE')} 2</label>
									<div className="company-value">
										{data.phone2}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="company-field">
									<label>{t('INN')}</label>
									<div className="company-value">
										{data.tin}
									</div>
								</div>
								<div className="company-field">
									<label>{t('CHECKING_ACCOUNT')}</label>
									<div className="company-value">
										{data.rs}
									</div>
								</div>
								<div className="company-field">
									<label>{t('MFO')}</label>
									<div className="company-value">
										{data.mfo}
									</div>
								</div>
								<div className="company-field">
									<label>{t('OKED')}</label>
									<div className="company-value">
										{data.oked}
									</div>
								</div>
							</div>
							<div className="col-md-3">
								<div className="company-field">
									<label>{t('ADDRESS')}</label>
									<div className="company-value">
										{data.address}
									</div>
								</div>
								<div className="company-field">
									<label>{t('REGION')}</label>
									<div className="company-value">
										{findFromArrayById(regions, data.regionId)}
									</div>
								</div>
								<div className="company-field">
									<label>{t('CITY')}</label>
									<div className="company-value">
										{findFromArrayById(cities, data.cityId)}
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default Index

