import React, { useEffect, useState, useRef } from "react"
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react';

import { GET, POST, PUT, FILE, DELETE } from 'helpers/adminApi'
import { Form } from "react-bootstrap";

function CreateUpdate() {
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate()

	const editorRefRu = useRef(null);
	const editorRefUz = useRef(null);

	const [questionRu, setQuestionRu] = useState('')
	const [questionUz, setQuestionUz] = useState('')

	const [data, setData] = useState({
		imageUrl: '',
		descriptionUz: '',
		descriptionRu: '',
		name: '',
		amount: '',
		days: '',
		trial: false,
		questions: false,
		questionsList: [],
	})

	function addQuestion() {
		var copyData = { ...data }
		copyData.questionsList = [...copyData.questionsList, { questionRu: questionRu, questionUz: questionUz }]
		setQuestionRu('')
		setQuestionUz('')
		setData(copyData)
	}

	async function removeQuestion(item, index) {
		if (item?.questionId) {
			await DELETE(`services/web/api/tariff-question/${item?.questionId}`)
		}
		var copyData = { ...data }
		copyData.questionsList.splice(index, 1)
		setData(copyData)
	}

	async function imageUpload(e) {
		let formData = new FormData();
		formData.append("image", e.target.files[0]);

		const response = await FILE('/services/web/api/upload/image', formData);
		setData({ ...data, 'imageUrl': response.url })
	}

	async function fileUpload(e) {
		let formData = new FormData();
		formData.append("file", e.target.files[0]);

		const response = await FILE('/services/web/api/upload/pdf20', formData);
		setData({ ...data, 'offerFile': response.fileName })
	}

	async function createUpdate(e) {
		e.preventDefault()
		var dataCopy = { ...data }
		if (editorRefUz.current) {
			dataCopy.descriptionUz = editorRefUz.current.getContent();
		}
		if (editorRefRu.current) {
			dataCopy.descriptionRu = editorRefRu.current.getContent();
		}

		var response;
		if (dataCopy.id) {
			response = await PUT('/services/web/api/tariff', dataCopy, { loader: true })
			if (response) {
				navigate('/tariff')
			}
		} else {
			response = await POST('/services/web/api/tariff', dataCopy, { loader: true })
			if (response) {
				navigate('/tariff')
			}
		}
	}

	async function getData() {
		const response = await GET(`/services/web/api/tariff/${id}`)
		setData(response)
		// editorRefRu
	}

	useEffect(() => {
		if (id) {
			getData()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('TARIFF')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('EDIT')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="row mb-5">
							<div className="col-md-5">
								<div className="con-upload">
									<div className="con-img-upload">
										{data.imageUrl &&
											<div className="img-upload">
												<button type="button" className="btn-x-file"
													onClick={() => setData({ ...data, 'imageUrl': '' })}>
													<i className="uil-times"></i>
												</button>
												<img src={'https://cabinet.kettma.uz' + data.imageUrl} className="w-100 h-100"
													style={{ maxWidth: 'none', maxHeight: '100%', objectFit: 'contain' }} alt="react" />
											</div>
										}
										{!data.imageUrl &&
											<div className="con-input-upload">
												<input type="file" onChange={(e) => imageUpload(e)} />
												<span className="text-input fz14">
													{t('IMAGE2')}
												</span>
											</div>
										}
									</div>
								</div>
								<div className="form-group">
									<label>{t('NAME3')}<span className="required-mark">*</span></label>
									<input type="text" className="form-control" name="name"
										value={data.name}
										onChange={(e) => setData({ ...data, 'name': e.target.value })} />
								</div>

								<div className="form-group">
									<label>{t('AMOUNT')}</label>
									<input type="number" className="form-control" name="amount"
										value={data.amount}
										onChange={(e) => setData({ ...data, 'amount': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('MONTH')}</label>
									<input type="number" className="form-control" name="days"
										value={data.days}
										onChange={(e) => setData({ ...data, 'days': e.target.value })} />
								</div>
								<div className="form-group">
									<label>{t('PRIVACY')}</label>
									<div>
										<input type="file" className="form-control" name="days" id="img"
											onChange={(e) => fileUpload(e)} accept="application/pdf" />
									</div>
								</div>
								<div className="d-flex gap-3">
									<div className="form-group">
										<label>{t("TRIAL")}</label>
										<Form.Check // prettier-ignore
											type="checkbox" checked={data.trial}
											onChange={(e) => setData({ ...data, 'trial': e.target.checked })}
										/>
									</div>
									<div className="form-group">
										<label>{t("QUESTIONS")}</label>
										<Form.Check // prettier-ignore
											type="checkbox" checked={data.questions}
											onChange={(e) => setData({ ...data, 'questions': e.target.checked })}
										/>
									</div>
								</div>
								{data.trial &&
									<div className="form-group">
										<label>{t('TRIAL_DAYS')}</label>
										<input type="number" className="form-control" name="days"
											value={data.trialDay}
											onChange={(e) => setData({ ...data, 'trialDay': e.target.value })} />
									</div>
								}
								{data.questions &&
									<div className="input-borer-only p-3 my-3">
										<table className="table table-striped">
											<thead>
												<tr>
													<th>{t('QUESTION')}(Ru)</th>
													<th>{t('QUESTION')}(Uz)</th>
													<th className="text-center">{t('ACTION')}</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<input type="text" className="form-control"
															value={questionRu} onChange={(e) => setQuestionRu(e.target.value)} />
													</td>
													<td>
														<input type="text" className="form-control"
															value={questionUz} onChange={(e) => setQuestionUz(e.target.value)} />
													</td>
													<td>
														<button type="button" className="btn btn-primary w-100" onClick={() => addQuestion()}>{t('ADD')}</button>
													</td>
												</tr>
												{data?.questionsList?.map((item, index) => (
													<tr key={index}>
														<td className="py-3">{item.questionRu}</td>
														<td className="py-3">{item.questionUz}</td>
														<td className="py-3">
															<div className="d-flex justify-content-center align-items-center h-100">
																<div className="table-action-button table-action-danger-button" onClick={() => removeQuestion(item, index)}>
																	<i className="uil-trash-alt"></i>
																</div>
															</div>
														</td>
													</tr>
												))
												}

											</tbody>
										</table>
									</div>
								}
							</div>

							<div className="col-md-7">
								<div className="form-group">
									<label>{t('DESCRIPTION')}(Uz)<span className="required-mark">*</span></label>
									<Editor
										apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
										onInit={(evt, editor) => editorRefUz.current = editor}
										language='ru_RU'
										initialValue={data.descriptionUz}
										init={{
											height: 300,
											menubar: true,
											plugins: [
												'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
												'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
												'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
											],
											toolbar: 'undo redo | blocks | ' +
												'bold italic forecolor | alignleft aligncenter ' +
												'alignright alignjustify | bullist numlist outdent indent | ' +
												'removeformat | help',
											content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
										}}
									/>
								</div>
								<div className="form-group">
									<label>{t('DESCRIPTION')}(Ru)<span className="required-mark">*</span></label>
									<Editor
										apiKey='6x64fxs5sj32o1blb14t1ptgpd2qqnhvz91ztkpvhu5qzqis'
										onInit={(evt, editor) => {
											editorRefRu.current = editor
										}}
										language='ru_RU'
										initialValue={data.descriptionRu}
										init={{
											height: 300,
											menubar: true,
											plugins: [
												'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
												'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
												'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
											],
											toolbar: 'undo redo | blocks | ' +
												'bold italic forecolor | alignleft aligncenter ' +
												'alignright alignjustify | bullist numlist outdent indent | ' +
												'removeformat | help',
											content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
										}}
									/>
								</div>
							</div>
						</div>

						<div className="btn-wrapper">
							<Link type="button" className="btn btn-warning btn-rounded btn-wide me-2" to="/tariff">{t('CANCEL')}</Link>
							<button type="submit" className="btn btn-primary btn-rounded btn-wide btn-wide">{t('SAVE')}</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

