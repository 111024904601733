import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts";

import Select from 'react-select'

import { GET } from 'helpers/api'
import useDidMountEffect from 'pages/parts/useDidMountEffect';
import { formatMoney } from 'helpers/helpers';

function Index() {
	const { t } = useTranslation()

	const [areaData, setAreaData] = useState({
		series: [
			{
				name: "",
				data: []
			},
		],
		options: {
			colors: ['#4AA173'],
			chart: {
				type: 'line',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			markers: {
				size: 5,
				colors: undefined,
				strokeWidth: 0,
				shape: "circle",
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				tooltip: {
					enabled: false
				},
				categories: [],
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})

	const [chartData, setChartData] = useState({
		series: [
			{
				name: "",
				data: []
			},
			{
				name: "",
				data: []
			}
		],

		options: {
			colors: ['#4AA173', '#acffd3'],
			chart: {
				type: 'area',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'straight'
			},
			markers: {
				size: 5,
				colors: undefined,
				strokeWidth: 0,
				shape: "circle",
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				tooltip: {
					enabled: false
				},
				categories: [],
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	})

	const [pieChartData, setPieChartData] = useState({
		// ...defaultChartData,
		charttype: 'donut',
		series: [],
		options: {
			colors: ['#FF6B6B', '#4ECDC4', '#FFD93D', '#1A535C', '#FF9F1C', '#2E86AB',],
			chart: {
				type: 'pie',
			},
			labels: [],
			dataLabels: {
				enabled: true
			},
			fill: {
				type: 'gradient',
			},
			legend: {
				show: false,
				position: 'bottom',
				formatter: function (val, opts) {
					return val + " - " + Math.round(opts.w.globals.series[opts.seriesIndex]) + ' %'
				},
				showForSingleSeries: false,
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: "left",
						labels: {
							colors: "#FFFFFF",
							useSeriesColors: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
				}
			}]
		},
	})

	const [clientsFlow, setClientsFlow] = useState({})
	const [tariffs, setTariffs] = useState([])
	const [tariffId, setTariffId] = useState(0)

	async function getIncome() {
		const response = await GET('/services/web/api/analytic/tariff/income', { tariff: tariffId })
		if (response) {
			var xaxis = {
				categories: [],
			}
			var data = []

			for (let i = 0; i < response.length; i++) {
				xaxis.categories.push(response[i]['x'])
				data.push(response[i]['y'])
			}


			setAreaData({
				...areaData,
				options: { ...chartData.options, xaxis: xaxis },
				series: [
					{
						name: 'Прибыль',
						data: data
					},
				]
			})
		}
	}

	async function getAvgSubscriptionPeriod() {
		const response = await GET('/services/web/api/analytic/tariff/avg-subscription-period', { tariff: tariffId })
		var series = []
		var options = {
			...pieChartData.options,
			labels: []
		}
		var prev = 0;
		for (let i = 0; i < response.length; i++) {
			series.push(response[i]['percentage'])
			var from = prev
			var to = Number(response[i]['tariffDays']) / Number(response[i]['periodCount']) * Number(response[i]['period'])
			if (response[i].period !== 6) {
				options.labels.push(from + ' до ' + to + 'дней')
			} else {
				options.labels.push(`> ${response[i]['tariffDays']} дней`)
			}
			prev = to + 1;
		}
		setPieChartData({
			...pieChartData,
			series: series,
			options: options,
		})
	}

	async function getClientsFlow() {
		const response = await GET('/services/web/api/analytic/tariff/clients-flow', { tariff: tariffId })
		if (response) {
			setClientsFlow(response)
			var xaxis = {
				categories: [],
			}
			var data = []
			var dataSecond = []

			for (let i = 0; i < response.length; i++) {
				xaxis.categories.push(response[i]['y'])
				data.push(response[i]['in'])
				dataSecond.push(response[i]['out'])
			}

			setChartData({
				...chartData,
				options: { ...chartData.options, xaxis: xaxis },
				series: [
					{
						name: t('IN'),
						data: data
					},
					{
						name: t('out'),
						data: dataSecond
					}
				]
			})
		}
	}

	async function getTariffs() {
		const response = await GET('/services/web/api/tariff-helper-company')
		if (response) {
			setTariffs(response)
			setTariffId(response[1]['id'])
		}
	}

	async function getData() {
		getIncome()
		getAvgSubscriptionPeriod()
		getClientsFlow()
	}

	useEffect(() => {
		getTariffs()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [tariffId]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center me-3">
					{t('ANALYTIC')}
				</h4>

				<div className="w-25">
					<Select
						options={tariffs}
						value={tariffs.find(option => option.id === tariffId) || ''}
						onChange={(option => setTariffId(option.id))}
						placeholder=""
						noOptionsMessage={() => t('LIST_IS_EMPTY')}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
					/>
				</div>
			</div>
			<div>
				<div className="card p-3 mt-3">
					<h4 className="mb-3">Прибыль за тариф</h4>
					<ReactApexChart
						options={areaData.options}
						series={areaData.series}
						type="line"
						height={300}
					/>
				</div>

				<div className="row">
					<div className="col-md-6" >
						<div className="card h-100 p-3 mt-3">
							<h4 className="mb-3">Средняя продолжительность подписки</h4>
							<ReactApexChart
								options={pieChartData.options}
								series={pieChartData.series}
								type="pie"
								height={300}
							/>
						</div>
					</div>

					<div className="col-md-6" >
						<div className="card h-100 p-3 mt-3">
							<h4 className="mb-3">Пришедшие / ушедшие клиенты</h4>
							<ReactApexChart
								options={chartData.options}
								series={chartData.series}
								type="area"
								height={300}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Index
