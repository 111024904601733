import React, { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import { showSidebar } from 'store/layout/actions'
import { store } from 'store/index'
import { Nav } from 'react-bootstrap'
//Import Scrollbar
import { exportMenu } from './menu'

import logo from "assets/images/logo.png";

function SidebarContent() {
	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()

	const [menu, setMenu] = useState(exportMenu)
	const [activeItem, setActiveItem] = useState({
		children: []
	})

	function setActiveMenu() {
		var menuCopy = [...menu]
		for (let k = 0; k < menuCopy.length; k++) {
			for (let i = 0; i < menuCopy[k]['children'].length; i++) {
				if (location?.pathname.includes(menuCopy[k]['children'][i]['url'])) {
					menuCopy[k]['children'][i]['active'] = true;
					setActiveItem(menuCopy[k])
				} else {
					menuCopy[k]['children'][i]['active'] = false;
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveChild(item, index) {
		store.dispatch(showSidebar())
		var copyArr = [...menu]
		const i = copyArr.findIndex(x => x.icon === activeItem.icon);
		copyArr[i]['children'][index].active = !copyArr[i]['children'][index].active
		setActiveItem(copyArr[i])
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="sidebar-menu-scroll">
				{activeItem.children.map((item, index) => (
					<Nav.Item key={index} onClick={() => navigate(item.url ?? '/')}>
						<Nav.Link  eventkey={item.icon}>
							<span className="fw-bold"><i className={item.icon}></i></span>
						</Nav.Link>
					</Nav.Item>
				))}
			</div>

			<div className="second-sidebar">

				<div className="second-sidebar-logo">
					<img src={logo} alt="" width={50} />
				</div>

				{
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{activeItem.children.map((item, index) => (
								<li className={item.active ? "mm-active" : ""} key={index}>
									{item.title == null ?
										<NavLink to={item.url}
											onClick={() => setActiveChild(item, index)} className={"waves-effect " + (item.active ? "active" : "")}>
											<i className={item.icon}></i>
											<span>{t(item.i18n)}</span>
										</NavLink>
										:
										<div className="second-sidebar-title">
											<span>{t(item.i18n)}</span>
										</div>
									}
								</li>
							))}
						</ul>
					</div>
				}
			</div>
		</>
	)
}

export default SidebarContent