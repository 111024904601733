import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

import { SET_ACCOUNT } from 'store/account/action'
import { GUESTPOST, GET } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz_latn.png'
import uzCyrl from 'assets/images/flags/uz_cyrl.png'

function Login() {
	const { i18n, t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [user, setUser] = useState({ "username": "", "password": "" })

	async function handleSubmit(e) {
		e.preventDefault();

		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}
		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })

		if (response) {
			dispatch(SET_ACCOUNT(response))
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			const account = await GET('/services/uaa/api/account')
			localStorage.setItem("authorities", JSON.stringify(account.authorities));

			var checker = false
			for (let i = 0; i < account.authorities.length; i++) {
				if (account.authorities[i] === "ROLE_COMPANY" || account.authorities[i] === "ROLE_ADMIN") {
					var role = "ROLE_COMPANY"
					checker = true
					if (account.authorities[i] === "ROLE_ADMIN")
						role = "ROLE_ADMIN"

					localStorage.setItem("role", role);

				}
			}

			if (checker === true && role === 'ROLE_ADMIN') {
				navigate("/admin/company")
				return
			}
			if (checker === true && role === 'ROLE_COMPANY') {
				console.log('here');
				navigate("/company")
				return
			}

			toast.error(t('ERROR') + ': ' + t('YOU_DO_NOT_HAVE_ACCESS'))
			clearTemporaryStorage()
		}
	}

	function changeLanguage(language = 'uz_latn') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz_latn') {
			localStorage.setItem('lang', 'uz_latn');
		}
		if (language === 'uz_cyrl') {
			localStorage.setItem('lang', 'uz_cyrl');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz_latn') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz_cyrl') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('LOGGING_IN')}</h3>
									</div>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
											<input className="login-input"
												name="username"
												autoFocus
												value={user.username}
												placeholder={t('LOGIN')}
												onChange={(e) => setUser({ ...user, 'username': e.target.value })} />

											<input className="login-input"
												name="password"
												type="password"
												placeholder={t('PASSWORD')}
												value={user.password}
												onChange={(e) => setUser({ ...user, 'password': e.target.value })} />

											<div className="text-center">
												<button type="submit" className="login-button">
													{t('ENTER')}
												</button>
											</div>

											<div className="mt-4 text-center">
												<Link to="/auth/forgot-password" className="fw-medium text-white">{t('FORGOT_PASSWORD')}</Link>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="position-absolute lang-position">
						<Dropdown className="cursor-pointer d-flex cursor">
							<Dropdown.Toggle as={LanguageToggle}>
								<div className="d-flex">
									<div className="vertical-center">
										<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
									</div>
									<span>{t(getCurrentLocale().lang)}</span>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{i18n.language !== 'ru' &&
									<Dropdown.Item onClick={() => changeLanguage("ru")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
											<span>{t('RUSSIAN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz_latn' &&
									<Dropdown.Item onClick={() => changeLanguage("uz_latn")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz_latn" width="24" height="16" /></div>
											<span>{t('UZBEK_LATN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz_cyrl' &&
									<Dropdown.Item onClick={() => changeLanguage("uz_cyrl")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz_cyrl" width="24" height="16" /></div>
											<span>{t('UZBEK_CYRL')}</span>
										</div>
									</Dropdown.Item>
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="about-mdokon position-absolute">
						{t('CONTACT_CENTRE')}: +998 55 500 80 09 <br />
						{t('MADE_BY_AS')} <br />
						{t('MADE_BY_AS2')}
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default Login;
