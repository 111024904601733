const initialState = {
	'size': 20,
	'decimalPoint': 2,
	'currencyId': '',
	'posId': '',
	'organizationId': '',
	'rememberLastDocumentInPreview': null,
	'documentsInCreate': {
		'name': true,
		'barcode': true,
		'balance': true,
		'quantity': true,
		'uomName': true,
		'price': true,
		'wholesalePrice': true,
		'salePrice': true,
		'serial': true,
		'expDate': true,
		'vat': true,
	},
	'tableControl': {
		'nomenclature': {
			'index': {
				'posName': true,
				'productName': true,
				'organizationName': true,
				'barcode': true,
				'price': true,
				'wholesalePrice': true,
				'salePrice': true,
				'currencyName': true,
				'serial': true,
				'expDate': true,
				'balance': true,
			}
		}
	},
}

const settings = function (state = initialState, action) {
	switch (action.type) {
		case "SET_SETTINGS":
			return action.payload;
		case "SET_PAGINATION_SIZE":
			return { ...state, 'size': action.payload };
		case "SET_DECIMAL_POINT":
			return { ...state, 'decimalPoint': action.payload };
		case "SET_DOCUMENTS_IN_CREATE":
			return { ...state, 'documentsInCreate': action.payload };
		case "SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW":
			return { ...state, 'rememberLastDocumentInPreview': action.payload };
		default:
			return state;
	}
};

export default settings