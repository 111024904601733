import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

import { GET } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'

function TariffPreview() {
	const { id } = useParams()
	const URL = '/services/web/api/users-orders/'
	const { t } = useTranslation()

	const [data, setData] = useState({})

	async function getData() {
		const response = await GET(URL + id, {}, { loader: true })
		if (!response.gpsPointX && !response.gpsPointY) {
			response.gpsPointX = 41.311081
			response.gpsPointY = 69.240562
		}
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	function buildRow(text, text2) {
		return <div className="d-flex align-items-center justify-content-between mb-2">
			<div>
				<b>{t(text) ?? ''}:</b>
			</div>
			<div>
				<b>{text2 ?? '- - - - - -'}</b>
			</div>
		</div>
	}

	return (
		<>
			<div className="page-header d-flex mb-2">
				<div className="d-flex">
					<h4 className="vertical-center">
						{t('CLIENTS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('PREVIEW')}</h6>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="row mb-5">
						<div className="col-md-6">
							<div className="fz-20 text-black text-center mb-3"><b>{t('USER')}</b></div>
							{buildRow(`${t('USER')} ID`, data.userId)}
							{buildRow('NAME', data.userName)}
							{buildRow('PHONE', data.userLogin)}
							{buildRow('CARD_NUMBER', data.userCardNumber)}
							{buildRow('ADDRESS', data.userAddressName)}
							{buildRow('ACTIVATED', data.activated ? 'ДА' : 'НЕТ')}
							<div className="fz-20 text-black text-center mb-3"><b>{t('TARIFF')}</b></div>
							{buildRow(`${t('TARIFF')} ${t('TITLE')}`, data.tariffName)}
							{buildRow('AMOUNT', formatMoney(data.tariffAmount))}
							{buildRow('DAYS', data.tariffDays)}
							{buildRow('START_DATE', data.startDate)}
							{buildRow('END_DATE', data.end_date)}
						</div>
						<div className="col-md-6">
							<YMaps
								query={{
									load: "package.full",
									apikey: '1c08a7da-6cda-4a21-a1e7-a6f7d9ba19e6'
								}}>
								<Map
									defaultOptions={{
										copyrightUaVisible: false,
										yandexMapDisablePoiInteractivity: true,
									}}
									state={{ center: [data.gpsPointX, data.gpsPointY], zoom: 11, controls: ["zoomControl"] }}
									style={{ height: '500px', width: '100%' }}
								>
									<Placemark geometry={[data.gpsPointX, data.gpsPointY]} />
								</Map>
							</YMaps>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TariffPreview