import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { GET, PUT } from 'helpers/adminApi'
import { formatDate } from 'helpers/helpers'

function Index() {
	const URL = '/services/admin/api/company-pageList'
	const { t } = useTranslation()


	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await GET('/services/admin/api/company-pageList', {}, { loader: true })
		if (response) {
			console.log(response);
			//setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response)
		}
	}

	async function handleChange(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			login: dataCopy[index]['login'],
			activated: e.target.checked,
		}
		await PUT('/services/admin/api/company-activate', sendData, { loader: true })
	}

	async function paginate(data) {
		const response = await GET('/services/admin/api/company-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('COMPANIES')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/admin/company/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('ID')}</th>
									<th>{t('IMAGE2')}</th>
									<th>{t('NAME3')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>
											{item.imageUrl ?
												<img src={'https://cabinet.kettma.uz' + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.name}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => handleChange(e, index)} />
												</div>
											</div>
										</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-primary-button"
													to={`/admin/company/update/${item.id}`}>
													<i className="uil-edit-alt"></i>
												</Link>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
