import React from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { publicRoutes, privateRoutes, adminPrivateRoutes } from './routes'

import Layout from 'containers/layout/Layout';
import LayoutAdmin from 'containers/layout/admin/Layout';
import NoAuthLayout from 'containers/layout/NoAuthLayout';

function createPublicRoutes() {
	return publicRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

function createPrivateRoutes() {
	return privateRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

function createAdminPrivateRoutes() {
	return adminPrivateRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedAdminRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

const ProtectedRoute = () => {
	const login = localStorage.getItem("username");
	var isAuthenticated = false;
	if (login) {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const ProtectedAdminRoute = () => {
	const username = localStorage.getItem("username");

	var isAuthenticated = false;
	if (username === 'admin') {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>
			<Route element={<Layout />}>
				{createPrivateRoutes()}
			</Route>
			<Route element={<Layout />}>
				<Route path="*" element={<Navigate to="/admin/company" replace />} />
			</Route>
			<Route path="/admin" element={<LayoutAdmin />}>
				<Route path="/admin" element={<Navigate to="/admin/users" replace />} />
				{createAdminPrivateRoutes()}
			</Route>

		</Routes>
	)
}

export default Index;