import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Modal, Dropdown } from 'react-bootstrap'

// Import menuDropdown
import LanguageDropdown from "../LanguageDropdown";
import logoIcon from "../../assets/images/logo_icon.png";

import { clearTemporaryStorage } from 'helpers/helpers'

function Header() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [modalLogout, setModalLogout] = useState(false)

	function tToggle() {
		var body = document.body;
		body.classList.toggle("vertical-collpsed");
		body.classList.toggle("sidebar-enable");
	}

	function toggleFullscreen() {
		if (
			!document.fullscreenElement &&
			!document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	async function logout() {
		clearTemporaryStorage()
		navigate('/auth/login')
	}

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link to="/" className="logo logo-dark">
								<span className="logo-sm">
									<img src={logoIcon} alt="" height="22" />
								</span>
							<span className="logo-lg">
									<img src={logoIcon} alt="" height="20" />
								</span>
							</Link>
						</div>
						<button id="vertical-menu-btn" type="button"
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
							onClick={() => tToggle()}>
							<i className="uil-bars fz-20" />
						</button>
					</div>

					<div className="d-flex">

						<LanguageDropdown />

						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button type="button" className="btn header-item noti-icon waves-effect" data-toggle="fullscreen"
								onClick={() => {
									toggleFullscreen();
								}}>
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<div className="d-lg-inline-block ms-1 dropdown" onClick={() => setModalLogout(true)}>
							<button type="button" className="btn header-item noti-icon waves-effect">
								<div className="d-flex">
									<div className="verical-center me-2">
										<div className="vertical-center h-100">
											<div className="d-flex">
												<div className="me-2">{t('LOGIN')}: {localStorage.getItem('username')}
												</div>
											</div>
										</div>
									</div>
									<div className="vertical-center">
										<i className="uil-signin"></i>
									</div>
								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
