import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import web from 'assets/web.pdf'

import { Document, Page, pdfjs } from 'react-pdf'
import axios from 'axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function PrivacyPoliceWeb() {
	const { id } = useParams()
	const location = useLocation()

	const [numPages, setNumPages] = useState(null);

	const [pdfUrl, setPdfUrl] = useState('')

	async function getData() {
		const response = await axios.get(`https://cabinet.kettma.uz/services/mobile/api/tariff/${id}`)
		if (response && response.data) {
			var imageUrl = await axios.get('https://cabinet.kettma.uz/' + response.data['offerFile'], {
				responseType: 'blob',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					mode: 'no-cors'
				}
			})
			setPdfUrl((imageUrl.data))
		}
	}

	useEffect(() => {
		if (id) {
			getData()
		}
	}, []);  // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{
			}
			{location.pathname.includes('mobile') &&
				<Document file={id ? pdfUrl : "./mobile.pdf"} className="w-100 h-100"
					onLoadSuccess={({ numPages }) => setNumPages(numPages)} renderTextLayer={false} renderMode='svg' error={<div className="fz-24">Ошибка при загрузке</div>}>
					{Array.apply(null, Array(numPages))
						.map((x, i) => i + 1)
						.map((page, index) =>
							<Page
								key={index}
								pageNumber={page}
							/>
						)
					}
				</Document>
			}
			{!location.pathname.includes('mobile') &&
				<iframe title="Пользовательское соглашение Kettma" src={web} height="100%" width="100%" />
			}



		</>
	)
}

export default PrivacyPoliceWeb