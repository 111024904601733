import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatDateWithTime, regexPhoneNumber } from "helpers/helpers"

function Index() {
	const URL = '/services/web/api/users-action-pageList'
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response.data) {
			setData(response.data)
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))
		}
	}

	async function paginate(data) {
		const response = await GET(URL, { page: data.selected, size: settings.size }, { loader: true })
		setData(response);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('ACTIONS_HISTORY')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('PHONE')}</th>
									<th>{t('TELEGRAM')} {t('PHONE')}</th>
									<th>{t('TARIFF')}</th>
									<th>{t('DATE')}</th>
									<th className="text-end">{t('ACTION_TYPE')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.rowNum}. {regexPhoneNumber(item.userLogin)}</td>
										<td>{regexPhoneNumber(item.telegramPhone)}</td>
										<td>{item.tariffName}</td>
										<td>{formatDateWithTime(item.actionDate)}</td>
										<td className="text-end">{item.actionType === 1 ? t('VIEWING') : t('PAYMENT_ATTEMPT')}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
