import { GUESTGET } from 'helpers/api';
import { formatDateWithTime, formatMoney } from 'helpers/helpers';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function PaymentInfo() {
	const { t } = useTranslation()

	const { transactionId } = useParams()

	const [data, setData] = useState({})

	async function getData() {
		const response = await GUESTGET(`/services/mobile/api/eight/cheque?uuid=${transactionId}`)
		if (response) {
			setData(response)
		}
	}

	useEffect(() => {
		getData()
	}, []);

	return (
		<>
			<div className="container p-3 position-relative h-100vh">
				<div className="text-center mb-2">
					<img src={"https://cabinet.kettma.uz" + data.brandLogoUrl} alt="" height={100} />
				</div>
				<div className="text-center mb-3">
					<b>{data.brandName}</b>
				</div>

				<div className="d-flex justify-content-between align-items-center fz-12 mb-1">
					<b>{t('CHEQUE')} UUID</b>
					<div>{data.transactionUUID}</div>
				</div>
				<div className="d-flex justify-content-between align-items-center fz-12 mb-1">
					<b>{t('DATE')}</b>
					<div>{formatDateWithTime(data.transactionDateTime)}</div>
				</div>
				<hr />
				<div className="d-flex justify-content-between align-items-center fz-12 mb-1">
					<b>{t('TARIFF_NAME')}</b>
					<div>{data.tariffName}</div>
				</div>
				<div className="fz-12 mb-1">
					<b>{t('DESCRIPTION')}</b>
					<div dangerouslySetInnerHTML={{ __html: (data.tariffDescription) }}></div>
				</div>
				<hr />
				<div className="d-flex justify-content-between align-items-center fz-12 mb-1">
					<b>{t('CARD')}</b>
					<div>{data.maskedPan}</div>
				</div>
				<div className="d-flex justify-content-between align-items-center fz-12 mb-1">
					<b>{t('STATUS')}</b>
					<div>
						{data.status === "succeeded" &&
							<div className="text-success">
								{t('SUCCESS')}
							</div>
						}
						{data.status === "canceled" &&
							<div className="text-danger">
								Отменено
							</div>
						}
					</div>
				</div>
				<div className="d-flex justify-content-between align-items-center fz-18 mb-3">
					<b>{t('TO_PAY')}</b>
					<b>{formatMoney(data.transactionAmount)} сум</b>
				</div>

				<div className="position-absolute fz-10" style={{ bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
					* Вы можете закрыть эту страницу
				</div>
			</div>
		</>
	)
}

export default PaymentInfo