import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import DatePicker from "react-datepicker"
import Select from 'react-select'
import { Dropdown, Modal, Form } from 'react-bootstrap'
import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import { GET, POST, PUT, FILE, PGET } from 'helpers/api'
import { findFromArrayById, formatDate, formatDateBackend, formatDateWithTime, regexPhoneNumber } from "helpers/helpers"
import { toast } from "react-toastify"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function Index() {
	const URL = '/services/web/api/users-pageList'
	const { t } = useTranslation()

	const date = new Date()

	const settings = useSelector(state => state.settings)

	const genders = [{ "id": 0, "name": t('MAN') }, { "id": 1, "name": t('WOMAN') }]

	const [showModal, setShowModal] = useState(false)
	const [showQuestionModal, setShowQuestionModal] = useState(false)
	const [showNotificationModal, setShowNotificationModal] = useState(false)
	const [showEditModal, setShowEditModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)

	const [pageCount, setPageCount] = useState(0)

	const [data, setData] = useState([])
	const [tariffs, setTariffs] = useState([])
	const [notes, setNotes] = useState([])
	const [questions, setQuestions] = useState([])
	const [regions, setRegions] = useState([])
	const [cities, setCities] = useState([])

	const [sendData, setSendData] = useState({
		"userId": '',
		"comment": ''
	})
	const [notification, setNotification] = useState({
		'titleUz': '',
		'titleRu': '',
		'messageUz': EditorState.createEmpty(),
		'messageRu': EditorState.createEmpty(),
		'sendAll': false,
		'sendParticularly': false,
		'usersId': [],
		'imageUrl': '',
	})
	const [filterData, setFilterData] = useState({
		// startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		// endDate: formatDateBackend(new Date()),
		search: '',
	})

	async function searchWithFilter() {
		const response = await GET(URL, filterData, { loader: true })
		if (response) {
			setData(response)
			setDropdownIsOpen(!dropdownIsOpen)
			setFilterDataExist(true)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	function resetFilter() {
		setFilterData({
			search: '',
			startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
			endDate: formatDateBackend(new Date()),
		})
		setFilterDataExist(false)
		getData()
	}

	async function sendNotification() {
		console.log(notification);
		var notificationCopy = { ...notification }
		notificationCopy.messageRu = draftToHtml(convertToRaw(notification.messageUz.getCurrentContent()))
		notificationCopy.messageUz = draftToHtml(convertToRaw(notification.messageUz.getCurrentContent()))
		setShowModal(false)
		const response = await POST(
			`/services/web/api/users-send-notification`,
			{ ...notificationCopy, ...filterData },
			{ loader: true }
		)
		if (response) {
			// toast.success(t('SUCCESS'))
			setShowNotificationModal(false)
		}
	}

	function handleChange(index) {
		var notificationCopy = { ...notification }
		var dataCopy = [...data]
		dataCopy[index].checked = !dataCopy[index].checked
		var ids = dataCopy?.filter(item => item.checked)
		if (ids?.length > 0) {
			notificationCopy.sendParticularly = true
			notificationCopy.usersId = [];
			for (let i = 0; i < ids.length; i++) {
				notificationCopy.usersId.push(ids[i]['userId'])
			}
			setNotification({ ...notification, ...notificationCopy })
		} else {
			setNotification({ ...notification, sendParticularly: false, usersId: [] })
		}
		setData(dataCopy)
	}

	function fileUpload(e, name) {
		let formData = new FormData();
		var reader = new FileReader();
		var file = e.target.files[0];
		if (file) {
			reader.readAsDataURL(file);
			reader.onload = function (e) {
				var image = new Image();
				image.src = e.target.result;
				image.onload = async function () {
					formData.append("image", file);
					const response = await FILE('/services/web/api/upload/image', formData);
					setNotification({ ...notification, 'imageUrl': response[0] })
				};
			};
		}
	}

	function openNotificationModal(item) {
		var notificationCopy = { ...notification }
		if (item) {
			notificationCopy.sendAll = false
			notificationCopy.usersId = []
			notificationCopy.usersId.push(item.userId)
		} else {
			notificationCopy.sendAll = true
			notificationCopy.usersId = []
		}
		setNotification(notificationCopy)
		setShowNotificationModal(true)
	}

	async function openModal(item) {
		const response = await GET(`/services/web/api/users-note/${item.userId}`)
		if (response) {
			setNotes(response)
			setSendData({ ...sendData, userId: item.userId })
			setShowModal(true)
		}
	}

	async function openQuestionModal(item) {
		const response = await GET(`/services/web/api/users-order-questions/${item.orderId}`)
		if (response) {
			setQuestions(response)
			setShowQuestionModal(true)
		}
	}

	function closeModal() {
		setShowModal(false)
	}

	function openEditModal(item) {
		setShowModal(false)
		setShowEditModal(true)
		if (item) {
			setSendData(item)
		}
	}

	function closeEditModal() {
		setShowEditModal(false)
		openModal(sendData)
	}

	async function createUpdate() {
		if (!sendData.comment) {
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
			return;
		}
		if (sendData.id) {
			const response = await PUT('/services/web/api/users-note', sendData)
			if (response) {
				closeEditModal()
			}
		} else {
			const response = await POST('/services/web/api/users-note', sendData)
			if (response) {
				closeEditModal()
			}
		}
		setSendData({
			"userId": '',
			"comment": ''
		})
	}

	async function selectRegion(option) {
		var dataCopy = { ...filterData }
		dataCopy.regionId = option.id
		dataCopy.cityId = null
		setFilterData(dataCopy)

		const response = await GET('/services/web/api/city-helper/' + option.id)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))
		}
	}

	async function getTariffs() {
		const response = await GET(`/services/web/api/tariff-helper-company`, {})
		if (response) {
			setTariffs(response)
		}
	}

	async function getRegions() {
		const response = await GET('/services/admin/api/region-helper')
		if (response.length > 0) {
			setRegions(response)
		}

		getCities(10)
	}

	async function getCities(id) {
		const response = await GET(`/services/admin/api/city-helper/${id}`)
		if (response.length > 0) {
			setCities(response)
		}
	}

	async function paginate(data) {
		const response = await GET(URL, { page: data.selected, size: settings.size }, { loader: true })
		setData(response);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0) 
		}
	}

	useEffect(() => {
		getData()
		getTariffs()
		getRegions()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				{notification?.sendParticularly ?
					<button className="btn-rounded btn btn-primary" onClick={() => setShowNotificationModal(true)}>
						{t('SEND_PUSH_NOTIFICATION_PARTICULARY')}
					</button>
					:
					<button className="btn-rounded btn btn-primary" onClick={() => openNotificationModal()}>
						{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
					</button>
				}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('GENDER')}</label>
											<Select
												options={genders}
												value={genders.find(option => option.id === filterData.gender) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'gender': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('TARIFF')}</label>
											<Select
												options={tariffs}
												value={tariffs.find(option => option.id === filterData.tariffId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'tariffId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>{t('REGION')}<span className="required-mark">*</span></label>
												<Select
													options={regions}
													value={regions.find(option => option.id === filterData.regionId) || ''}
													onChange={(option => selectRegion(option))}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>{t('CITY')}<span className="required-mark">*</span></label>
												<Select
													options={cities}
													value={cities.find(option => option.id === filterData.cityId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cityId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label>{t('AGE_FROM')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'startAge': e.target.value })} />
										</div>
										<div className="form-group col-md-6">
											<label>{t('AGE_TO')}</label>
											<input type="text" className="form-control" onChange={(e) => setFilterData({ ...filterData, 'lastAge': e.target.value })} />
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.ageFrom &&
									<span className="me-2">
										<b>{t('AGE_FROM')}: </b> {filterData.ageFrom}
									</span>
								}
								{filterData.ageTo &&
									<span className="me-2">
										<b>{t('AGE_TO')}: </b> {filterData.ageTo}
									</span>
								}
								{filterData.tariffId &&
									<span className="me-2">
										<b>{t('TARIFF')}: </b> {findFromArrayById(tariffs, filterData.tariffId)}
									</span>
								}
								{filterData.regionId &&
									<span className="me-2">
										<b>{t('REGION')}: </b> {findFromArrayById(regions, filterData.regionId)}
									</span>
								}
								{filterData.cityId &&
									<span className="me-2">
										<b>{t('CITY')}: </b> {findFromArrayById(cities, filterData.cityId)}
									</span>
								}
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{(filterData.gender === 0 || filterData.gender === 1) &&
									<span className="me-2">
										<b>{t('GENDER')}: </b>
										{filterData.gender === 1 ?
											<span>{t('WOMAN')}</span>
											:
											<span>{t('MAN')}</span>
										}
									</span>
								}
								{filterData.startAge &&
									<span className="me-2">
										<b>{t('AGE_FROM')}: </b> {filterData.startAge}
									</span>
								}
								{filterData.lastAge &&
									<span className="me-2">
										<b>{t('AGE_TO')}: </b> {filterData.endAge}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th></th>
									<th>{t('REGION')}</th>
									<th className="text-center">{t('CITY')}</th>
									<th className="text-center">{t('NAME')}</th>
									<th className="text-center">{t('LAST_NAME')}</th>
									<th className="text-center">{t('PHONE')}</th>
									<th className="text-center">{t('TELEGRAM')}</th>
									<th className="text-center">{t('EMAIL')}</th>
									<th className="text-center">{t('GENDER')}</th>
									<th className="text-center">{t('BIRTHDAY')}</th>
									<th className="text-center">{t('AGE')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('TARIFF')}</th>
									<th className="text-center">{t('DATE')}</th>
									<th className="text-center">{t('END_DATE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.rowNum}</td>
										<td>
											<Form.Check // prettier-ignore
												type="checkbox"
												value={item.checked}
												checked={item.checked}
												onChange={() => handleChange(index)}
											/>
										</td>
										<td>{item.regionName}</td>
										<td>{item.cityName}</td>
										<td className="text-center">{item.userName}</td>
										<td className="text-center">{item.userLastName}</td>
										<td className="text-center text-nowrap">{regexPhoneNumber(item.userLogin)}</td>
										<td className="text-center text-nowrap">{regexPhoneNumber(item.telegramPhone)}</td>
										<td className="text-center">{item.email}</td>
										<td className="text-center">{item.userGender ? t('WOMAN') : t('MAN')}</td>
										<td className="text-center">{formatDate(item.userBirthDate)}</td>
										<td className="text-center">{item.userAge}</td>
										<td className={"text-center " + (item.subscriber ? 'text-success' : 'text-danger')}>{item.subscriber ? t('YES') : t('NO')}</td>
										<td className="text-center">{item.tariffName}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="text-center">{formatDate(item.endDate)}</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link className="table-action-button table-action-primary-button me-2"
													to={"/user/" + item.userId}>
													<i className="uil-transaction"></i>
												</Link>
												<div className="table-action-button table-action-primary-button me-2"
													onClick={() => openModal(item)}>
													<i className="uil-comment-dots"></i>
												</div>
												<div className="table-action-button table-action-primary-button"
													onClick={() => openQuestionModal(item)}>
													<i className="uil-question"></i>
												</div>
											</div>

										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Modal show={showQuestionModal} animation={false} size="lg"
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowQuestionModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex justify-content-between align-items-center w-100">
						{t('QUESTIONS')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: '700px', overflow: 'auto' }}>
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th>{t('QUESTION')}</th>
								<th className="text-center">{t('ANSWER')}</th>
							</tr>
						</thead>
						<tbody>
							{questions.map((item, index) => (
								<tr className="tr-middle" key={index}>
									<td>{item.question}</td>
									<td className="text-center">{item.answer}</td>
								</tr>
							))}
						</tbody>
					</table>
				</Modal.Body>
			</Modal>

			<Modal show={showModal} animation={false} size="lg"
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex justify-content-between align-items-center w-100">
						{t('COMMENTS')}
						<div className="btn-rounded btn btn-primary" onClick={() => openEditModal()}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ maxHeight: '700px', overflow: 'auto' }}>
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th>{t('CLIENT')}</th>
								<th className="text-center" style={{ width: '50%', padding: '0' }}>{t('COMMENT')}</th>
								<th className="text-center">{t('DATE')}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{notes.map((item, index) => (
								<tr className="tr-middle" key={index}>
									<td>{item.userName} {item.userLastName}</td>
									<td>
										<div className="text-wrap" style={{ width: '400px', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											{item.comment}
										</div>
									</td>
									<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
									<td>
										<div className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button"
												onClick={() => openEditModal(item)}>
												<i className="uil-edit"></i>
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Modal.Body>
			</Modal>

			<Modal show={showEditModal} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeEditModal()}>
				<Modal.Header closeButton>
					<Modal.Title className="d-flex justify-content-between">
						{sendData.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group">
						<label>{t('COMMENT')}<span className="required-mark">*</span></label>
						<textarea type="text" className="form-control" rows="8"
							value={sendData.comment} autoFocus={true}
							onChange={(e) => setSendData({ ...sendData, 'comment': e.target.value })} />
					</div>
					<div className="d-flex w-100 mt-3">
						<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeEditModal()}>{t('CANCEL')}</button>
						<button type="button" className="btn btn-primary w-100" onClick={() => createUpdate()}>{t('SAVE')}</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* MODAL NOTIFICATION */}
			<Modal show={showNotificationModal} animation={false} centered dialogClassName="update-modal-width" size="lg"
				backdrop="static" onHide={() => setShowNotificationModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>
						{notification.sendAll ?
							<div>
								{t('SEND_PUSH_NOTIFICATION_TO_EVERYONE')}
							</div>
							:
							<div>
								{t('SEND_PUSH_NOTIFICATION')}
							</div>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '700px', overflow: 'auto' }}>
					<form onSubmit={() => sendNotification()} autoComplete="off">
						<div className="form-group">
							<label>{t('NAME3')} (ru)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'titleRu': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NAME3')} (uz)<span className="required-mark">*</span></label>
							<input type="text" className="form-control" onChange={(e) => setNotification({ ...notification, 'titleUz': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (ru)<span className="required-mark">*</span></label>
							<Editor
								editorState={notification.messageRu}
								wrapperClassName="demo-wrapper"
								editorClassName="demo-editor"
								onEditorStateChange={(e) => setNotification({ ...notification, 'messageRu': e })}
							/>
						</div>
						<div className="form-group">
							<label>{t('DESCRIPTION')} (uz)<span className="required-mark">*</span></label>
							<Editor
								editorState={notification.messageUz}
								wrapperClassName="demo-wrapper"
								editorClassName="demo-editor"
								onEditorStateChange={(e) => setNotification({ ...notification, 'messageUz': e })}
							/>
						</div>
						<p className="text-danger mb-3">
							{t('YOU_ARE_RESPONSIBLE')}
						</p>
						<div>
							<div className="con-upload">
								<div className="con-img-upload">
									{notification.imageUrl &&
										<div className="img-upload">
											<button type="button" className="btn-x-file" onClick={() => setNotification({ ...notification, 'imageUrl': '' })}>
												<i className="uil-times"></i>
											</button>
											<img src={'https://cabinet.cashbek.uz' + notification.imageUrl}
												style={{ maxWidth: 'none', maxHeight: '100%' }} alt="react" />
										</div>
									}
									{!notification.imageUrl &&
										<div className="con-input-upload">
											<input type="file" onChange={(e) => fileUpload(e)} />
											<span className="text-input fz14">
												{t('IMAGE2')}
											</span>
										</div>
									}
								</div>
							</div>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => setShowNotificationModal(false)}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100"
								disabled={!notification.titleUz || !notification.titleRu || !notification.messageRu || !notification.messageUz}>
								{t('SEND')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL NOTIFICATION */}
		</>
	)
}

export default Index
