export let exportMenu = [
	{
		icon: "uil-home-alt sidebar-icon",
		children: [
			{
				title: true,
				i18n: "MENU",
			},
			{
				url: "/admin/company",
				i18n: "COMPANIES",
				icon: "uil-shop",
				active: false,
			},
		]
	},
]