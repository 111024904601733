import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Dropdown } from 'react-bootstrap'

import ru from 'assets/images/flags/ru.png'
import uzLatn from 'assets/images/flags/uz_latn.png'
import uzCyrl from 'assets/images/flags/uz_cyrl.png'
import axios from "axios"

function MobileLogin() {
	const { i18n, t } = useTranslation()

	const [isSubmit, seIsSubmit] = useState(false)
	const [user, setUser] = useState({ "phone": "", "activationCode": "" })

	async function handleSubmit(e) {
		e.preventDefault()
		if (isSubmit) {
			var userCopy = { ...user }
			if (!user['activationCode']) {
				toast.error(t('ERROR'))
				return
			}
			userCopy.phone = '998' + userCopy.phone
			const response = await axios.post('https://cabinet.kettma.uz/services/mobile/api/delete-client-confirm', userCopy).catch(e => { })
			console.log(response);
			if (response.data['success']) {
				toast.success(t('ACCOUNT_HAS_BEEN_SUCCESSFULLY_DELETED'))
				seIsSubmit(false)
				setUser({ "phone": "", "activationCode": "" })
			} else {
				toast.error(t('ERROR') + ': ' + response.data['message'])
			}
		} else {
			userCopy.phone = '998' + userCopy.phone
			const response = await axios.post('https://cabinet.kettma.uz/services/mobile/api/delete-client', userCopy).catch(e => {
				if (e?.response?.status === 401) {
					toast.error(t('LOGIN_OR_PASSWORD_INVALID'))
				}
			})
			console.log(response);
			if (response.data['success']) {
				toast.success(response.data['message'])
				seIsSubmit(true)
			} else {
				toast.error(t('ERROR') + ': ' + response.data['message'])
			}
		}
	}

	function changeLanguage(language = 'uz_latn') {
		i18n.changeLanguage(language);

		if (language === 'ru') {
			localStorage.setItem('lang', 'ru');
		}
		if (language === 'uz_latn') {
			localStorage.setItem('lang', 'uz_latn');
		}
		if (language === 'uz_cyrl') {
			localStorage.setItem('lang', 'uz_cyrl');
		}
	};

	function getCurrentLocale() {
		const locale = i18n.language
		if (locale === 'ru') return { flag: ru, lang: 'RUSSIAN' }
		else if (locale === 'uz_latn') return { flag: uzLatn, lang: 'UZBEK_LATN' }
		else if (locale === 'uz_cyrl') return { flag: uzCyrl, lang: 'UZBEK_CYRL' }
	}

	const LanguageToggle = React.forwardRef(({ children, onClick }, ref) => (
		<span ref={ref} onClick={(e) => { e.preventDefault(); onClick(e); }}>
			{children}
		</span>
	));

	return (
		<>
			<div className="authentication-bg">
				<div className="account-pages h-100 vertical-center">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-md-8 col-lg-6 col-xl-4">

								<div className="auth-card">
									<div className="text-center my-2">
										<h3 className="text-white">{t('ACCOUNT_DELETION_REQUEST')}</h3>
										{isSubmit &&
											<small className="text-white">
												{t('ALL_DATA_ABOUT_YOUR_ACCOUNT_WILL_BE_DELETED')}

											</small>
										}
									</div>
									<div className="p-2">
										<form className="form-horizontal" autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
											{isSubmit ?
												<input className="login-input w-100"
													name="activationCode"
													autoFocus
													value={user.activationCode}
													placeholder={t('PHONE_NUMBER')}
													onChange={(e) => setUser({ ...user, 'activationCode': e.target.value })} />
												:
												<div className="position-relative">
													<div className="login-input-span">
														+998
													</div>
													<input className="login-input mobile"
														name="phone"
														autoFocus
														value={user.phone}
														placeholder={t('PHONE_NUMBER')}
														onChange={(e) => setUser({ ...user, 'phone': e.target.value })} />
												</div>
											}
											<div className="text-center">
												{isSubmit ?
													<button type="submit" className="login-button w-100" >
														{t('CONFIRM')}
													</button>
													:
													<button type="submit" className="login-button w-100" >
														{t('GET_SMS_CODE')}
													</button>
												}
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="position-absolute lang-position">
						<Dropdown className="cursor-pointer d-flex cursor">
							<Dropdown.Toggle as={LanguageToggle}>
								<div className="d-flex">
									<div className="vertical-center">
										<img src={getCurrentLocale().flag} className="me-2" alt="ru" width="24" height="16" />
									</div>
									<span>{t(getCurrentLocale().lang)}</span>
								</div>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{i18n.language !== 'ru' &&
									<Dropdown.Item onClick={() => changeLanguage("ru")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={ru} className="me-2" alt="ru" width="24" height="16" /></div>
											<span>{t('RUSSIAN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz_latn' &&
									<Dropdown.Item onClick={() => changeLanguage("uz_latn")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzLatn} className="me-2" alt="uz_latn" width="24" height="16" /></div>
											<span>{t('UZBEK_LATN')}</span>
										</div>
									</Dropdown.Item>
								}
								{i18n.language !== 'uz_cyrl' &&
									<Dropdown.Item onClick={() => changeLanguage("uz_cyrl")}>
										<div className="d-flex">
											<div className="vertical-center"><img src={uzCyrl} className="me-2" alt="uz_cyrl" width="24" height="16" /></div>
											<span>{t('UZBEK_CYRL')}</span>
										</div>
									</Dropdown.Item>
								}
							</Dropdown.Menu>
						</Dropdown>
					</div>

					<div className="about-mdokon position-absolute">
						Kettma <br />
						{t('CONTACT_CENTRE')}: +998 93 807-05-05 <br />
						{t('MADE_BY_AS')} <br />
					</div>

					<ul className="circles">
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</ul>
				</div>
			</div>
		</>
	)
}

export default MobileLogin;
